import React from 'react'
import { GetContentData } from 'components/getContentData'

/**
 * @description Простой блок
 */
const Block = (
  {
    classes,
    children,
    ...rest
  }
) => {
  
  return rest.text
    ? (
      <div className={classes}>
        <GetContentData content={rest.text}/>
      </div>
    )
    : <div className={classes} {...rest}>{children}</div>
}

export default Block
