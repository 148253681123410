import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'
import styles from './SignIn.module.scss'
import classNames from 'classnames'
import { contacts } from 'common/data/contacts'

/**
 * @description Авторизация
 */

const SignIn = (
  {
    classes
  }
) => {
  const {t} = useTranslation()
  return (
    <Button classes={classNames(
      styles.signIn,
      classes
    )}>
      <a href={`mailto:${contacts.email}`} title={contacts.email}>
        {t('contact')}
      </a>
    </Button>
  )
}

export default SignIn

