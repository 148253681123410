import React from 'react'
import styles from './PrivacyPage.module.scss'
import { GetContentData } from 'components/getContentData'
import { data } from 'common/data/data'
import { Container } from 'layouts/Container'
import { Block } from 'components/block'

/**
 * @description Страница с пользовательским соглашением
 */
const PrivacyPage = () => {
  
  return (
    <Block className={styles.privacyText}>
      <Container>
        <GetContentData content={data.privacy}/>
      </Container>
    </Block>
  )
}

export default PrivacyPage
