import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { App } from 'features/app'
import reportWebVitals from './reportWebVitals'
import './assets/styles/global.scss'

import 'common/utils/i18n'

const container = document.getElementById('a1')
const root = createRoot(container)

const app = (
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
)

root.render(app)


reportWebVitals()
