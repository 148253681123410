export const data = {
  siteName: 'a1',
  privacy: 'texts/privacy/privacy.md',
  eula: 'texts/eula/eula.md',
  items: [
    {
      title: 'your_security',
      description: {
        ru: 'texts/ru/yourSecurity.md',
        en: 'texts/en/yourSecurity.md',
        am: 'texts/am/yourSecurity.md',
      },
      bg: 'bg/bg1.png',
      buttons: true
    },
    {
      title: 'will_provide',
      description: {
        ru: 'texts/ru/willProvide.md',
        en: 'texts/en/willProvide.md',
        am: 'texts/am/willProvide.md',
      },
      image: 'sections/s2.png',
      bg: 'bg/bg2.png'
    },
    {
      title: 'reasons',
      description: {
        ru: 'texts/ru/reasons.md',
        en: 'texts/en/reasons.md',
        am: 'texts/am/reasons.md',
      },
      image: 'sections/s3.png',
      bg: 'bg/bg3.png'
    },
    {
      title: 'on_guard_of_order',
      description: {
        ru: 'texts/ru/guard.md',
        en: 'texts/en/guard.md',
        am: 'texts/am/guard.md',
      },
      image: 'sections/s4.png',
      bg: 'bg/bg4.png'
    },
    {
      title: 'help',
      description: {
        ru: 'texts/ru/help.md',
        en: 'texts/en/help.md',
        am: 'texts/am/help.md',
      },
      image: 'sections/s5.png',
    },
    {
      title: 'how_work',
      description: {
        ru: 'texts/ru/howWork.md',
        en: 'texts/en/howWork.md',
        am: 'texts/am/howWork.md',
      },
      image: 'sections/s6.png',
      images: [
        'iPhone 12 Pro Max 1 1.png',
        'iPhone 12 Pro Max 2 1.png',
        'iPhone 12 Pro Max 3 1.png',
      ],
      bg: 'bg/bg5.png'
    },
    {
      title: 'together',
      description: {
        ru: 'texts/ru/together.md',
        en: 'texts/en/together.md',
        am: 'texts/am/together.md',
      },
      image: 'sections/s7.png',
      buttons: true
    },
  ]
}
