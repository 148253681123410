import React from 'react'
import classNames from 'classnames'
import styles from './Image.module.scss'

/**
 * @description Просто картинка
 */
const Image = (
  {
    src,
    name,
    classes,
    ...rest
  }
) => {
  
  if (!src) {
    return null;
  }
  
  return (
    <figure
      className={classNames(
        styles.figure,
        classes
      )}
      {...rest}
    >
      <img
        src={src}
        alt={name}
        className={styles.image} />
    </figure>
  );
};

export default Image
