import React from 'react'
import { useTranslation } from 'react-i18next'
import {Link} from "react-router-dom";

/**
 * @description Пользовательское соглашение
 */
const UserAgreement = () => {

    const {t} = useTranslation()

    return (
        <Link to={'/eula'} rel={'nofollow noreferrer'}>{t('user_agreement')}</Link>
    )
}

export default UserAgreement
