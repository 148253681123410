import React from 'react'
import { Container } from 'layouts/Container'
import { Logo } from 'components/logo'
import { Nav } from 'components/nav'
import { Block } from 'components/block'
import { LangButton } from 'components/lang'
import { SignIn } from 'components/signIn'
import { NavMobile, NavState } from 'components/navMobile'
import { useCurrentWidth } from 'common/hooks/useCurrentWidth'
import styles from './Header.module.scss'

/**
 * @description Шапка
 */
const Header = () => {
  
  let width = useCurrentWidth()
  
  return (
    <header>
      <Container>
        {width <= 1024 ? (
          <Block classes={styles.wrapperMobile}>
            <Logo/>
            <NavState>
              <NavMobile/>
            </NavState>
          </Block>
        ) : (
          <Block classes={styles.wrapper}>
            <Logo/>
            <nav className={styles.navList}>
              <Nav/>
            </nav>
            <Block classes={styles.buttons}>
              <LangButton/>
              <SignIn/>
            </Block>
          </Block>
        )}
      </Container>
    </header>
  )
}

export default Header
