import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import styles from './LangButton.module.scss'
import {Button} from 'components/button'
import arrow from 'assets/images/arrow.svg'
import {Block} from 'components/block'
import {languagesList} from 'common/data/languages'
import {useCurrentWidth} from 'common/hooks/useCurrentWidth'

/**
 * @description Кнопка переключения языка
 */
const LangButton = () => {

    const {i18n} = useTranslation()
    const ref = useRef()

    const [lang, setLang] = useState({})
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [open])

    const onHandleChange = () => {
        setOpen(!open)
    }

    const handleClick = (option) => {
        i18n.changeLanguage(option.code)
        setLang(option)
        setOpen(!open)
    }

    let width = useCurrentWidth()

    const LangsList = () => {

        let currentLang = lang.code ?? 'ru'

        return (
            <Block classes={styles.langsList}>
                <ul>
                    {languagesList.data.map((option) => (
                        <li
                            className={currentLang === option.code ? styles.active : ''}
                            key={option.code}
                            onClick={() => handleClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            </Block>
        )
    }

    return (
        <div ref={ref}>
            <Button
                handleChange={onHandleChange}
                classes={classNames(
                    styles.lang,
                    {
                        [styles.langsMobile]: width <= 576,
                        [styles.open]: open
                    })}>
                <span>{lang.label ? lang.label : 'Русский'}</span>
                <img src={arrow} alt="arrow"/>
            </Button>

            {open && <LangsList/>}
        </div>
    )
}

export default LangButton
