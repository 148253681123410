import React from 'react'
import { Container } from 'layouts/Container'
import { Block } from 'components/block'
import { data } from 'common/data/data'
import { Social } from 'components/social'
import { Terms } from 'components/terms'
import { Contacts } from 'components/contacts'
import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'
import { useCurrentWidth } from 'common/hooks/useCurrentWidth'
import {City} from "../city";
import {UserAgreement} from "../userAgreement";

/**
 * @description Подвал
 */
const Footer = () => {
  
  let width = useCurrentWidth()
  
  return (
    <footer id={'footer'}>
      <Container type={width >= 576 && 'small'}>
        <Block classes={styles.wrapper}>
          
          {/* Социальные сети */}
          <Block classes={styles.dateSocial}>
            <Block>
              &copy;&nbsp;
              <Link to={'/'}>{data.siteName.toUpperCase()}</Link>
              ,&nbsp;{new Date().getFullYear()}
            </Block>
            <Social/>
          </Block>
    
          {/* Политика конфиденциальности и пользовательское соглашение */}
          <Block classes={styles.terms}>
            <UserAgreement/>
            <Terms/>
          </Block>
    
          {/* Адрес */}
          <Block classes={styles.contacts}>
            <City/>
            <Contacts/>
          </Block>
  
        </Block>
      </Container>
    </footer>
  )
}

export default Footer
