import React, { useContext } from 'react';
import { MenuContext } from './NavState';
import styles from './HamburgerButton.module.scss'
import classNames from 'classnames'

const HamburgerButton = () => {
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext);
  
  const clickHandler = () => {
    toggleMenuMode();
  };
  
  return (
    <div
      className={classNames(
        styles.navButton,
        {
          [styles.active]: isMenuOpen && 'active'
        }
      )}
      aria-label="Открыть главное меню"
      onClick={clickHandler}
    >
      <span className={styles.span}/>
      <span className={styles.span}/>
      <span className={styles.span}/>
    </div>
  );
};

export default HamburgerButton;
