import { useEffect, useState } from 'react'

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth

/**
 * Хук получения размера окна
 * */
export const useCurrentWidth = () => {
  
  let [width, setWidth] = useState(getWidth())
  
  useEffect(() => {
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
    }
    window.addEventListener('resize', resizeListener)
    
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])
  
  return width
}
