import { ReactComponent as Facebook } from 'assets/images/facebook.svg'
import { ReactComponent as Instagram } from 'assets/images/instagram.svg'
import { ReactComponent as Linkedin } from 'assets/images/linkedin.svg'
import { ReactComponent as Telegram } from 'assets/images/telegram.svg'
import { ReactComponent as Twitter } from 'assets/images/twitter.svg'
import { ReactComponent as VK } from 'assets/images/vk.svg'

export const socials = [
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/company/a1app',
    icon: Linkedin
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/A1-109649548403738/',
    icon: Facebook
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/A1apps',
    icon: Twitter
  },
  {
    title: 'Instagram',
    link: 'https://instagram.com/a1apps?igshid=YmMyMTA2M2Y=',
    icon: Instagram
  },
  {
    title: 'Telegram',
    link: 'https://t.me/a1apps',
    icon: Telegram
  },
  {
    title: 'VK',
    link: 'https://vk.com/a1app',
    icon: VK
  },
]
