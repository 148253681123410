import React from 'react'
import styles from './EulaPage.module.scss'
import { GetContentData } from 'components/getContentData'
import { data } from 'common/data/data'
import { Container } from 'layouts/Container'
import { Block } from 'components/block'

/**
 * @description Страница с пользовательским соглашением
 */
const EulaPage = () => {

    return (
        <Block className={styles.eulaText}>
            <Container>
                <GetContentData content={data.eula}/>
            </Container>
        </Block>
    )
}

export default EulaPage
