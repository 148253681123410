import React from 'react'
import classNames from 'classnames'
import styles from './Container.module.scss'

/**
 * Обычный контейнер
 */
const Container = (
  {
    classes,
    type,
    children
  }
) => {
  return (
    <div className={classNames(
      styles.container,
      classes,
      {
        [styles.medium]: type === 'medium',
        [styles.small]: type === 'small',
      }
    )}>
      {children}
    </div>
  )
}

export default Container
