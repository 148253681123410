import React, { PureComponent } from 'react'
import MDEditor from '@uiw/react-md-editor'
import axios from 'axios'

export default class GetContentData extends PureComponent {
  
  state = {
    content: null,
  }
  
  getText = e => {
    if (e) {
      axios.get(e).then(rawText => this.setState({ content: rawText.data }))
    }
  }
  
  componentDidMount () {
    this.getText(this.props.content)
  }
  
  componentDidUpdate (prevProps, _prevState) {
    if (prevProps.content !== this.props.content) {
      this.getText(this.props.content)
    }
  }
  
  componentWillUnmount () {
    this.setState({ content: null })
  }
  
  render () {
    return <MDEditor.Markdown source={this.state.content}/>
  }
}
