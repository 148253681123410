import React from "react";

/**
 * Заголовок
 */
const Title = (
  {
    classes,
    ...rest
  }
) => {
  
  return (
    <rest.as
      className={classes}>
      {rest.title}
    </rest.as>
  );
};

export default Title;
