import React from 'react'
import { contacts } from 'common/data/contacts'

/**
 * @description Адрес
 */
const City = () => {

    return (
        <>
            <span>{contacts.address}</span>
        </>
    )
}

export default City
