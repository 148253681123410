import React from 'react'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { ScrollToTop } from 'components/scrollToTop'
import { Section } from 'layouts/Section'

/**
 * @description Базовый шаблон
 */
const BaseLayout = ({ children }) => {
  return (
    <>
      <Section>
        <Header/>
      </Section>
      <ScrollToTop/>
      <main className={'main'}>
        {children}
      </main>
      <Section>
        <Footer/>
      </Section>
    </>
  )
}

export default BaseLayout
