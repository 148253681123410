import React from 'react'
import styles from './Button.module.scss'
import classNames from 'classnames'

/**
 * Кнопка
 */
const Button = (
  {
    classes,
    disabled,
    handleChange,
    children,
    ...rest
  }
) => {
  return (
    <button
      className={classNames(
        styles.button,
        classes
      )}
      onClick={handleChange}
      type={'submit'}
      disabled={disabled}
      {...rest}>
      <div>{children}</div>
    </button>
  )
}

export default Button
