import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as LogoA1 } from 'assets/images/logo.svg'

/**
 * Логотип
 */
const Logo = () => <Link to={'/'}><LogoA1/></Link>

export default Logo
