import React from 'react'
import classNames from 'classnames'
import styles from './Section.module.scss'

/**
 * Секция
 */
const Section = (
  {
    classes,
    children,
    ...rest
  }
) => {
  return (
    <section
      className={classNames(
        styles.section,
        styles[rest.name],
        classes
      )}>
      {children}
    </section>
  )
}

export default Section
