import React from 'react'
import { contacts } from 'common/data/contacts'

/**
 * @description Контакты
 */
const Contacts = () => {
  
  return (
    <>
      {/*<a href={`tel:${contacts.phone.replace(/[^+0-9]/g, '')}`}>{contacts.phone}</a>*/}
      <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
      {/*<p>{contacts.address}</p>*/}
    </>
  )
}

export default Contacts
