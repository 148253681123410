import React from 'react'
import { socials } from 'common/data/social'
import { Block } from 'components/block'
import styles from './Social.module.scss'

/**
 * @description Кнопки соц.сетей
 */
const Social = () => {
  return (
    <Block classes={styles.socialsList}>
      {socials.map((item, k) => (
        <a href={item.link} key={k} target={'_blank'} rel={'nofollow noopener noreferrer'}>
          <item.icon />
        </a>
      ))}
    </Block>
  )
}

export default Social
