import React, { useContext, useRef } from 'react'
import styles from './NavMobile.module.scss'
import { HamburgerButton, SideMenu } from './'
import useOnClickOutside from 'common/hooks/useOnClickOutside'
import { MenuContext } from './NavState'

/**
 * @description Мобильное меню
 */
const NavMobile = () => {
  
  const node = useRef();
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext);
  useOnClickOutside(node, () => {
    if (isMenuOpen) {
      toggleMenuMode();
    }
  });
  
  return (
      <div ref={node}>
        <div className={styles.navBar}>
          <HamburgerButton/>
        </div>
        <SideMenu/>
      </div>
  )
}

export default NavMobile
