import React, { useContext } from 'react'
import styles from './SideMenu.module.scss'
import { MenuContext } from './NavState'
import classNames from 'classnames'
import { Nav } from 'components/nav'
import { SignIn } from 'components/signIn'
import { LangButton } from 'components/lang'

/**
 * @description SideMenu
 */
const SideMenu = () => {
  const { isMenuOpen } = useContext(MenuContext)
  
  return (
    <nav className={classNames(
      styles.items,
      {
        [styles.open]: isMenuOpen
      }
    )}>
      <Nav/>
      <LangButton/>
      <SignIn classes={styles.auth} />
    </nav>
  )
}

export default SideMenu
