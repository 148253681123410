import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

/**
 * @description Политика конфиденциальности
 */
const Terms = () => {

  const {t} = useTranslation()

  return (
      <Link to={'/privacy'} rel={'nofollow noreferrer'}>{t('privacy_policy')}</Link>
  )
}

export default Terms
