import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DefaultPage } from 'features/home'
import { BaseLayout } from 'layouts/BaseLayout'
import EulaPage from "../eula/EulaPage";
import PrivacyPage from "../privacy/PrivacyPage";

function App ({ children }) {

    return (
        <BaseLayout>
            <Routes>
                {children}
                <Route path="/" element={<DefaultPage/>} exact={true}/>
                <Route path="/privacy" element={<PrivacyPage/>}/>
                <Route path="/eula" element={<EulaPage/>}/>
            </Routes>
        </BaseLayout>
    )
}

export default App
