import React from 'react'
import { navList } from 'common/data/nav'
import { useTranslation } from 'react-i18next'

/**
 * @description Верхнее меню
 */
const Nav = () => {
  const { t } = useTranslation()
  return navList.map((item) => (
    <a href={item.link} key={item.link}>{t(item.title)}</a>
  ))
}

export default Nav
