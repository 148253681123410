import React from 'react'
import styles from './DefaultPage.module.scss'
import { data } from 'common/data/data'
import { Section } from 'layouts/Section'
import { Container } from 'layouts/Container'
import { ItemSection } from 'components/itemSection'

/**
 * @description Страница по умолчанию
 */
const DefaultPage = () => data.items.map((item, k) => (
    <Section
      name={item.title && item.title}
      key={k}
    >
      <Container
        classes={styles.sectionContainer}>
        
        {/* Отдельный блок с описанием элементов сервиса */}
        <ItemSection
          classes={k % 2 !== 0 && styles.reverse}
          key={`${k}_${item.title}`}
          {...item}/>
      
      </Container>
    </Section>
  )
)

export default DefaultPage
