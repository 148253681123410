/* Языки */
export const languagesList = {
  name: 'languages',
  title: 'languages_list',
  data: [
    {
      id: 0,
      value: 'Русский',
      label: 'Русский',
      code: 'ru',
      use_in_ui: true
    },
    {
      id: 1,
      value: 'English',
      label: 'English',
      code: 'en',
      use_in_ui: true
    },
    {
      id: 2,
      value: 'Հայերեն',
      label: 'Հայերեն',
      code: 'am',
      use_in_ui: true
    }
  ]
};
