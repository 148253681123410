import React from 'react'
import classNames from 'classnames'
import { Title } from 'components/title'
import { Block } from 'components/block'
import { Image } from 'components/image'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GooglePlay } from 'assets/images/GooglePlay.svg'
import { ReactComponent as AppStore } from 'assets/images/AppStore.svg'
import styles from './ItemSection.module.scss'
import { useCurrentWidth } from 'common/hooks/useCurrentWidth'

/**
 * Элемент в списке услуг
 */
const ItemSection = (
  {
    classes,
    children,
    ...rest
  }
) => {
  
  const { t, i18n } = useTranslation()
  let width = useCurrentWidth()
  
  const src = width <= 576 ? rest.image_mobile ? rest.image_mobile : rest.image : rest.image
  
  return (
    <Block
      id={rest.title}
      classes={classNames(
        styles.item,
        classes
      )}>
      
      <Block
        classes={styles.itemContainer}>
        <Block>
          
          {/* Заголовок в секции */}
          <Title as={'h2'} title={t(rest.title)}/>
          
          {/* Текст в секции */}
          <Block
            classes={classNames(
              styles.textContainer,
              {
                [styles.simple]: !rest.buttons
              }
            )}
            text={rest.description[i18n.language]}/>
          
          {/* Кнопки скачивания */}
          {rest.buttons && (
            <Block classes={styles.download}>
              <AppStore/>
              <GooglePlay/>
            </Block>
          )}
        </Block>
      </Block>
      
      {/*
        Если есть несколько картинок - отображаем их горизонтально со скролом.
        Иначе отображаем как картинку
       */}
      {rest.images
        ? (
          <Block classes={styles.phonesList}>
            {rest.images.map((item) => <Image src={`images/phones/${item}`} key={item}/>)}
          </Block>
        )
        : rest.image && <Image src={`images/${src}`}/>}
    </Block>
  )
}

export default ItemSection
